import type { ThemeOptions } from '@mui/material/styles/createTheme';
import type { CustomThemeOptionsProps } from '../../types';
interface ProjectThemeOptions extends ThemeOptions, CustomThemeOptionsProps {
   h1?: {
    fontSize: string,
    fontWeight:any
  },h2?: {
    fontSize: string,
    fontWeight:any
  },
  h3?: {
    fontSize: string,
    fontWeight:any
  },
  h4?: {
    fontSize: string,
    fontWeight:any
  },
  h5?: {
    fontSize: string,
    fontWeight:any
  },
  h6?: {
    fontSize: string,
    fontWeight:any
  },
  palette?: any,
  typography?: any
}

export const themeOptions: ProjectThemeOptions = {"palette":{"mode":"light","text":{"primary":"#ffffff","secondary":"#ffffff"},"action":{"hover":"#6c3ab7"},"primary":{"main":"#8c4ac8"},"secondary":{"main":"#8848c3","light":"#ffffff"},"background":{"paper":"#8c4ac8","default":"#4c1474"}},"typography":{"h1":{"fontSize":"1.5rem","fontWeight":700},"h2":{"fontSize":"1.5rem","fontWeight":500},"h3":{"fontSize":"1.5rem","fontWeight":500},"h4":{"fontSize":"1.5rem"},"h5":{},"h6":{},"body1":{},"body2":{},"button":{},"caption":{},"overline":{},"subtitle1":{},"subtitle2":{},"fontFamily":"Quicksand, Helvetica, Arial, sans-serif","htmlFontSize":16},"theme_component_list_arrow_width":"50","theme_component_list_arrow_height":"50","theme_component_card_border_radius":"10","theme_component_list_arrow_left_left":"-25","theme_component_list_arrow_min_width":"50","theme_component_list_arrow_right_right":"-25","theme_component_drawer_background_color":"#464646","theme_component_search_field_icon_color":"#ffffff","theme_component_search_field_input_color":"#ffffff","theme_component_list_arrow_minimum_height":"50","theme_component_footer_bar_background_image":"","theme_component_list_arrow_background_color":"#464646","theme_component_list_arrow_left_border_radius":"0 50% 50% 0","theme_component_list_arrow_left_svg_font_size":"16","theme_component_search_field_icon_hover_color":"rgba(255, 255, 255, 0.12)","theme_component_list_arrow_right_border_radius":"50% 0 0 50%","theme_component_list_arrow_right_svg_font_size":"16","theme_component_list_arrow_left_svg_margin_left":"24","theme_component_list_arrow_right_svg_margin_right":"24","theme_component_navigation_menu_item_active_color":"#ff7600","theme_component_navigation_bar_background_image_gradient":"linear-gradient(to bottom, #875fb3, #4f317c)","theme_component_navigation_menu_item_hover_background_color":"rgba(255, 255, 255, 0.04)","theme_component_navigation_menu_item_active_background_color":"rgba(255, 255, 255, 0.54)"};